import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { moveItemInArray } from '@angular/cdk/drag-drop';
import { combineAll } from 'rxjs/operators';
import { DialogService } from 'app/service/dialog.service';
import { DialogMessageComponent } from '../dialog-message/dialog-message.component';

@Component({
  selector: 'app-dialog-display-device',
  templateUrl: './dialog-display-device.component.html',
  styleUrls: ['./dialog-display-device.component.scss']
})
export class DialogDisplayDeviceComponent implements OnInit {

  /**
   * list header
   */
  headers;

  /**
  * list header hidden
  */
  headersHidden;

  /**
   * column is selected
   */
  columnSelected

  /**
   * true if first column is selected
   */
  isTop: boolean = false;
  /**
   * true if last colum is selected
   */
  isBottom: boolean = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData, public dialogRef: MatDialogRef<DialogDisplayDeviceComponent>
  , public dialogService: DialogService,) { }

  ngOnInit(): void {
    if (this.data.header) {
      this.headers = JSON.parse(JSON.stringify(this.data.header));
      this.selectColumn(this.headers[0].headerName)
    }
  }

  /**
   * select column
   * @param column name of column selected 
   */
  selectColumn(column: string) {
    this.columnSelected = column;
    let index = this.headers.findIndex(data => data.headerName == this.columnSelected);
    if (index == this.headers.length - 1 && !this.isBottom) {
      this.isBottom = true;
    } else if (index != this.headers.length - 1 && this.isBottom) {
      this.isBottom = false;
    } else if (index == 0 && !this.isTop) {
      this.isTop = true;
    } else if (index != 0 && this.isTop) {
      this.isTop = false;
    }
  }

  /**
   * move selected column on top
   */
  upToTop() {
    if (this.columnSelected) {
      let index = this.headers.findIndex(data => data.headerName == this.columnSelected)
      if (!this.isTop) {
        this.isTop = true;
      }
      if (this.isBottom) {
        this.isBottom = false;
      }
      moveItemInArray(this.headers, index, 0);
    }
  }

  /**
   * move the selected column up
   */
  up() {
    if (this.columnSelected) {
      if (this.isBottom) {
        this.isBottom = false;
      }
      let index = this.headers.findIndex(data => data.headerName == this.columnSelected)
      if (index > 1) {
        moveItemInArray(this.headers, index, index - 1);
      } else if (index == 1) {
        moveItemInArray(this.headers, index, index - 1);
        this.isTop = true;
      }
    }
  }

  /**
   * move the selected column down
   */
  down() {
    if (this.columnSelected) {
      if (this.isTop) {
        this.isTop = false;
      }
      let index = this.headers.findIndex(data => data.headerName == this.columnSelected)
      if (index < this.headers.length - 2) {
        moveItemInArray(this.headers, index, index + 1);
      } else if (index == this.headers.length - 2) {
        moveItemInArray(this.headers, index, index + 1);
        this.isBottom = true;
      }
    }
  }

  /**
   * move the selected column on bottom
   */
  downToBottom() {
    if (this.columnSelected) {
      let index = this.headers.findIndex(data => data.headerName == this.columnSelected)
      if (this.isTop) {
        this.isTop = false;
      }
      if (!this.isBottom) {
        this.isBottom = true;
      }
      moveItemInArray(this.headers, index, this.headers.length - 1);
    }
  }

  /**
   * save
   */
  save() {
    if (this.headers.every(data => data.isChecked)) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: `Error`,
          text: `Please select at least one column.`
        }
      })
      return;
    }
    this.dialogRef.close(this.headers);
  }
}

/**
 * export dialog data
 */
export interface DialogData {
  header: [];
}