import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Helper } from 'app/common/helper';
import { Constant, DeviceTypeEnum } from 'app/config/constants';
import { DeviceScreenSize } from 'app/model/entity/device-screen-size';
import { APICustomerService } from 'app/service/api-customer.service';
import { CommonService } from 'app/service/common.service';
import { DeviceService } from 'app/service/device.service';
import { ExecutingService } from 'app/service/executing.service';
import _ from 'lodash';
import moment from 'moment';
import { CustomTag } from '../../model/entity/custom-tag';
import { Device } from '../../model/entity/device';
import { CustomTagService } from '../../service/custom-tag.service';
import { DialogService } from '../../service/dialog.service';
import { DialogMessageComponent } from '../dialog-message/dialog-message.component';
import { Common } from 'app/model/entity/common';

@Component({
  selector: 'app-dialog-device-manager',
  templateUrl: './dialog-device-manager.component.html',
  styleUrls: ['./dialog-device-manager.component.scss']
})
export class DialogDeviceManagerComponent implements OnInit {
  Constant = Constant;
  DeviceTypeEnum = DeviceTypeEnum;
  static readonly LENGTH_REGISTRATION_ID = 12;
  static readonly MAX_LENGTH_DEVICE_ID = 16;
  static readonly MAX_LENGTH_BUS_ID = 16;
  static readonly MAX_LENGTH_DEVICE_COMMENT = 256;
  readonly FORMAT_TIME_AUTO_SHUTDOWN = 'HH:mm';
  readonly DEFAULT_SCREEN_ORIENTATION = 'Normal';
  readonly DEFAULT_SCREEN_SIZE_WIDTH = 1920;
  readonly DEFAULT_SCREEN_SIZE_HEIGHT = 1080;
  readonly TIMEOUT_SEC = 2;
  readonly ERROR_STATUS = 'call-status-failed';
  readonly ERROR_DISPLAY = 'call-display-failed';
  readonly ERROR_SHUTDOWN_TIME = 'call-shutdown-time-failed';

  /**
   * list custom tag
   */
  listCustomTag: Array<CustomTag> = new Array<CustomTag>();
  /**
   * device edit
   */
  deviceEdit: Device;

  /**
   * shutDownTimeClone
   */
  shutDownTimeClone: any;
  /**
   * device form
   */
  deviceForm: FormGroup;
  /**
   *  list character registration id
   */
  characterRegistrationIds: Array<String> = new Array<String>(DialogDeviceManagerComponent.LENGTH_REGISTRATION_ID);
  /**
   * title dialog
   */
  title: string = 'Registration';
  /**
   * list input registration id
   */
  inputRegistrationIds = Array(DialogDeviceManagerComponent.LENGTH_REGISTRATION_ID);
  /**
   * true if editing
   */
  isEdit = false;
  /**
   * language key
   */
  languageKey: string;
  /**
   * config
   */
  config: any;
  /**
   * screen size
   */
  deviceScreenSize: DeviceScreenSize;
  /**
   * device edit old
   */
  deviceEditOld: Device;
  /**
   * screen size old
   */
  deviceScreenSizeOld: DeviceScreenSize;
  private commonObject: Common;

  constructor(
    private customTagService: CustomTagService,
    public dialogRef: MatDialogRef<DialogDeviceManagerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private dialogService: DialogService,
    private deviceService: DeviceService,
    private commonService: CommonService,
    private translateService: TranslateService,
    private apiCustomerService: APICustomerService,
    private executingService: ExecutingService
  ) {}

  ngOnInit(): void {
    this.languageKey = this.commonService.getCommonObject().setting?.language;
    if (this.data.device) {
      this.deviceEdit = _.cloneDeep(this.data.device);
      this.isEdit = true;
      this.title = 'Edit';
      this.deviceEdit.isAutoShutdown = this.deviceEdit.timeAutoShutDown != null;
      this.deviceEdit.timeAutoShutDown = moment(this.deviceEdit.timeAutoShutDown, this.FORMAT_TIME_AUTO_SHUTDOWN);
      this.fillRegistrationId();
    } else {
      this.deviceEdit = new Device('', '', '');
      this.characterRegistrationIds.fill('');
    }
    this.deviceScreenSize = this.deviceEdit?.screenSize
      ? <DeviceScreenSize>JSON.parse(this.data.device.screenSize)
      : new DeviceScreenSize(this.DEFAULT_SCREEN_SIZE_WIDTH, this.DEFAULT_SCREEN_SIZE_HEIGHT);
    this.fetchDeviceData();
    this.validateForm();
    this.deviceEditOld = _.cloneDeep(this.deviceEdit);
    this.deviceScreenSizeOld = _.cloneDeep(this.deviceScreenSize);
    setTimeout(() => {
      if (this.deviceEdit.isAutoShutdown) {
        this.updateConfig();
        Helper.customizeTimePicker(this.languageKey);
      }
    });
    this.commonObject = this.commonService.getCommonObject();
  }

  /**
   * fetch group device
   */
  private fetchDeviceData(): void {
    this.customTagService.getCustomTag().subscribe(
      data => {
        this.listCustomTag = data;
      },
      error => {
        this.dialogRef.close();
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: `Error`,
            text: `An error has occurred. Please try again.`
          }
        });
      }
    );
  }

  /**
   * validate form
   */
  private validateForm(): void {
    this.deviceForm = new FormGroup({
      registrationID: new FormGroup({
        regisIdNumber1: new FormControl(this.characterRegistrationIds[0]),
        regisIdNumber2: new FormControl(this.characterRegistrationIds[1]),
        regisIdNumber3: new FormControl(this.characterRegistrationIds[2]),
        regisIdNumber4: new FormControl(this.characterRegistrationIds[3]),
        regisIdNumber5: new FormControl(this.characterRegistrationIds[4]),
        regisIdNumber6: new FormControl(this.characterRegistrationIds[5]),
        regisIdNumber7: new FormControl(this.characterRegistrationIds[6]),
        regisIdNumber8: new FormControl(this.characterRegistrationIds[7]),
        regisIdNumber9: new FormControl(this.characterRegistrationIds[8]),
        regisIdNumber10: new FormControl(this.characterRegistrationIds[9]),
        regisIdNumber11: new FormControl(this.characterRegistrationIds[10]),
        regisIdNumber12: new FormControl(this.characterRegistrationIds[11])
      }),
      deviceId: new FormControl(this.deviceEdit?.deviceId),
      screenOrientation: new FormControl(this.deviceEdit?.screenOrientation ?? this.DEFAULT_SCREEN_ORIENTATION),
      type: new FormControl(this.deviceEdit?.type ?? DeviceTypeEnum.DESTINATION_SIGN),
      busId: new FormControl(this.deviceEdit?.busId),
      comment: new FormControl(this.deviceEdit?.comment),
      customTag0: new FormControl(this.deviceEdit?.customTag0?.id),
      customTag1: new FormControl(this.deviceEdit?.customTag1?.id),
      customTag2: new FormControl(this.deviceEdit?.customTag2?.id),
      customTag3: new FormControl(this.deviceEdit?.customTag3?.id),
      customTag4: new FormControl(this.deviceEdit?.customTag4?.id),
      screenSizeWidth: new FormControl(this.deviceScreenSize.width),
      screenSizeHeight: new FormControl(this.deviceScreenSize.height)
    });
  }

  /**
   * covert string registration to array
   */
  private fillRegistrationId(): void {
    for (let i = 0; i < this.deviceEdit.registrationId.length; i++) {
      this.characterRegistrationIds[i] = this.deviceEdit.registrationId[i];
    }
  }

  /**
   * save
   */
  public save(): void {
    let registrationID = this.getStringRegistrationId();
    if (!this.isEdit && registrationID.trim().length == DialogDeviceManagerComponent.LENGTH_REGISTRATION_ID) {
      // edit k check
      this.deviceService.getDeviceByRegisId(registrationID).subscribe(
        data => {
          if (data) {
            document.getElementById('regisIdNumber1').focus();
            this.dialogService.showDialog(DialogMessageComponent, {
              data: {
                title: `Error`,
                text: `That Registration ID is taken.`
              }
            });
            return;
          }
          this.getDeviceToSave();
        },
        error => {
          this.dialogRef.close();
          this.dialogService.showDialog(DialogMessageComponent, {
            data: {
              title: `Error`,
              text: `An error has occurred. Please try again.`
            }
          });
        }
      );
    } else {
      this.getDeviceToSave();
    }
  }

  /**
   * convert array to string
   */
  private getStringRegistrationId(): any {
    let registrationIDs = Object.values(this.deviceForm.value.registrationID);
    return registrationIDs.join('');
  }

  /**
   * toggle auto shutdown device
   */
  public toggleAutoShutdownDevice(): void {
    this.deviceEdit.isAutoShutdown = !this.deviceEdit.isAutoShutdown;
    setTimeout(() => {
      if (this.deviceEdit.isAutoShutdown) {
        this.updateConfig();
        Helper.customizeTimePicker(this.languageKey);
      }
    });
  }

  /**
   * get device from form
   */
  private async getDeviceToSave(): Promise<void> {
    if (this.validateDevice()) {
      if (!this.isEdit) {
        this.deviceEdit.registrationId = this.getStringRegistrationId();
      }
      let payloadGroupDeviceRemove: any = new Array<any>();
      let payloadGroupDeviceAdd: any = new Array<any>();

      for (let i = 0; i < this.listCustomTag.length; i++) {
        let customTagElement = `customTag${this.listCustomTag[i].indexCustom}`;
        let indexElementSelected = this.listCustomTag[i].elements?.findIndex(data => data.id == this.deviceForm.value[customTagElement]);
        const customTagOfDevice = this.deviceEditOld[customTagElement];
        const element = this.listCustomTag[i].elements[indexElementSelected];

        if ([Constant.SIGNAGE_GROUP, Constant.ANNOUNCE_GROUP].includes(this.listCustomTag[i].name)) {
          // change element of custom tag
          if (indexElementSelected != -1) {
            // add device or change device element from empty to another element
            if (!this.isEdit || (this.isEdit && !customTagOfDevice)) {
              payloadGroupDeviceAdd.push(Helper.createPayloadGroupDevice(element.groupId, [this.deviceEdit.registrationId]));

              // change element to another element
            } else if (this.isEdit && customTagOfDevice.id != element.id) {
              payloadGroupDeviceRemove.push(Helper.createPayloadGroupDevice(customTagOfDevice.groupId, [this.deviceEdit.registrationId]));
              payloadGroupDeviceAdd.push(Helper.createPayloadGroupDevice(element.groupId, [this.deviceEdit.registrationId]));
            }

            // edit device and change element to blank
          } else if (this.isEdit && customTagOfDevice) {
            payloadGroupDeviceRemove.push(Helper.createPayloadGroupDevice(customTagOfDevice.groupId, [this.deviceEdit.registrationId]));
          }
        }

        this.deviceEdit[customTagElement] = indexElementSelected != -1 ? element : null;
      }

      this.deviceEdit.deviceId = this.deviceForm.value.deviceId;
      this.deviceEdit.type = this.deviceForm.value.type;
      this.deviceEdit.screenOrientation = this.deviceForm.value.screenOrientation;
      this.deviceEdit.busId = this.deviceForm.value.busId?.trim();
      this.deviceEdit.comment = this.deviceForm.value.comment?.trim();
      this.deviceScreenSize.width = this.deviceForm.value.screenSizeWidth;
      this.deviceScreenSize.height = this.deviceForm.value.screenSizeHeight;
      this.deviceEdit.screenSize = JSON.stringify(this.deviceScreenSize);

      const isChangedDisplay = this.changedDisplay();
      const isAreaGroup = this.changedAreaGroup();
      const isAnnouncementGroup = this.changedAnnouncementGroup();
      const isChangedShutdownTime = this.changedShutdownTime();
      this.shutDownTimeClone = _.cloneDeep(this.deviceEdit.timeAutoShutDown);
      if (
        !this.isEdit ||
        isChangedShutdownTime ||
        isChangedDisplay ||
        isAnnouncementGroup ||
        isAreaGroup ||
        payloadGroupDeviceRemove ||
        payloadGroupDeviceAdd
      ) {
        let payloadShutdownTime: any;
        let payloadDisplay: any;
        if (!this.isEdit || isChangedShutdownTime) {
          payloadShutdownTime = {
            device: this.deviceEdit.registrationId,
            shutdown_time: this.getShutdownTime(this.deviceEdit.isAutoShutdown, this.shutDownTimeClone, true)
          };
        }
        if (!this.isEdit || isChangedDisplay) {
          payloadDisplay = {
            device: this.deviceEdit.registrationId,
            display_direction: this.deviceEdit.screenOrientation.toLowerCase(),
            display_size: this.convertDisplaySize(this.deviceScreenSize.width, this.deviceScreenSize.height)
          };
        }

        await this.callAPI(payloadShutdownTime, payloadDisplay, isChangedShutdownTime, isChangedDisplay, isAreaGroup);
        await this.callAPIGroupDevice(payloadGroupDeviceAdd, payloadGroupDeviceRemove);
      }
      this.deviceEdit.timeAutoShutDown = this.getShutdownTime(this.deviceEdit.isAutoShutdown, this.shutDownTimeClone);
      this.dialogRef.close(this.deviceEdit);
    }
  }

  /**
   * call API group device
   * @param payloadGroupDeviceAdd
   * @param payloadGroupDeviceRemove
   * @returns
   */
  private async callAPIGroupDevice(payloadGroupDeviceAdd: any, payloadGroupDeviceRemove: any): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (payloadGroupDeviceRemove.length) {
        const deletePromises = payloadGroupDeviceRemove.map(payload => this.apiCustomerService.groupDeviceRemove(payload).toPromise());
        Promise.all(deletePromises)
          .then(() => {
            if (payloadGroupDeviceAdd.length) {
              const addPromises = payloadGroupDeviceAdd.map(payload => this.apiCustomerService.groupDeviceAdd(payload).toPromise());
              Promise.all(addPromises)
                .then(() => {
                  resolve();
                })
                .catch(addError => {
                  this.dialogService.showDialog(DialogMessageComponent, {
                    data: {
                      title: `Error`,
                      text: `Group device add failed.`
                    }
                  });
                  reject();
                });
            } else {
              resolve();
            }
          })
          .catch(deleteError => {
            this.dialogService.showDialog(DialogMessageComponent, {
              data: {
                title: `Error`,
                text: `Group device remove failed.`
              }
            });
            reject();
          });
      } else if (payloadGroupDeviceAdd.length) {
        const addPromises = payloadGroupDeviceAdd.map(payload => this.apiCustomerService.groupDeviceAdd(payload).toPromise());
        Promise.all(addPromises)
          .then(() => {
            resolve();
          })
          .catch(addError => {
            this.dialogService.showDialog(DialogMessageComponent, {
              data: {
                title: `Error`,
                text: `Group device add failed.`
              }
            });
            reject();
          });
      } else {
        resolve();
      }
    });
  }

  /**
   * call API
   *
   * @param payloadShutdownTime
   * @param payloadDisplay
   * @param isChangedShutdownTime
   * @param isChangedDisplay
   * @returns
   */
  private async callAPI(
    payloadShutdownTime: any,
    payloadDisplay: any,
    isChangedShutdownTime: boolean,
    isChangedDisplay: boolean,
    isChangedAreaGroup: boolean
  ): Promise<void> {
    return new Promise<void>(resolve => {
      if (!this.isEdit || (isChangedDisplay && isChangedShutdownTime && isChangedAreaGroup)) {
        this.apiCustomerService.setShutdownTime(payloadShutdownTime).subscribe(
          () => {
            this.apiCustomerService.setDisplay(payloadDisplay).subscribe(
              async () => {
                const areaGroupIndex = this.listCustomTag[2].elements.findIndex(e => e.id == this.deviceForm.value.customTag2);
                if (areaGroupIndex != -1) {
                  const areaGroupName = this.listCustomTag[2].elements[areaGroupIndex].name;
                  const id = (Math.floor(Math.random() * (100 - 1 + 1)) + 1).toString();
                  const tenant = this.commonObject?.tenantName.toUpperCase();
                  let payloads = {
                    id: id,
                    accessToken: sessionStorage.getItem('access_token'),
                    refreshToken: sessionStorage.getItem('refresh_token'),
                    payload: {
                      devices: [
                        {
                          registrationId: this.deviceEdit.registrationId,
                          parent: tenant,
                          label: this.deviceEdit.deviceId
                        }
                      ],
                      command: 'setAreaGroup',
                      params: {
                        area_group: areaGroupName
                      }
                    }
                  };
                  this.deviceService.commandDevice(payloads).subscribe(async () => {
                    this.executingService.executing();
                    await this.timeout(this.TIMEOUT_SEC * 1000);
                    await this.callAPIStatus(true, true);
                    resolve();
                  });
                } else {
                  resolve();
                }
              },
              () => {
                this.handleErrorFromApi(this.ERROR_DISPLAY);
                return;
              }
            );
          },
          () => {
            this.handleErrorFromApi(this.ERROR_SHUTDOWN_TIME);
            return;
          }
        );
      } else if (isChangedShutdownTime) {
        this.apiCustomerService.setShutdownTime(payloadShutdownTime).subscribe(
          async () => {
            this.executingService.executing();
            await this.timeout(this.TIMEOUT_SEC * 1000);
            this.callAPIStatus(isChangedShutdownTime, isChangedDisplay);
            resolve();
          },
          () => {
            this.handleErrorFromApi(this.ERROR_SHUTDOWN_TIME);
            return;
          }
        );
      } else if (isChangedDisplay) {
        this.apiCustomerService.setDisplay(payloadDisplay).subscribe(
          async () => {
            this.executingService.executing();
            await this.timeout(this.TIMEOUT_SEC * 1000);
            this.callAPIStatus(isChangedShutdownTime, isChangedDisplay);
            resolve();
          },
          () => {
            this.handleErrorFromApi(this.ERROR_DISPLAY);
            return;
          }
        );
      } else if (isChangedAreaGroup) {
        const areaGroupIndex = this.listCustomTag[2].elements.findIndex(e => e.id == this.deviceForm.value.customTag2);
        if (areaGroupIndex != -1) {
          const areaGroupName = this.listCustomTag[2].elements[areaGroupIndex].name;
          const id = (Math.floor(Math.random() * (100 - 1 + 1)) + 1).toString();
          const tenant = this.commonObject?.tenantName.toUpperCase();
          let payloads = {
            id: id,
            accessToken: sessionStorage.getItem('access_token'),
            refreshToken: sessionStorage.getItem('refresh_token'),
            payload: {
              devices: [
                {
                  registrationId: this.deviceEdit.registrationId,
                  parent: tenant,
                  label: this.deviceEdit.deviceId
                }
              ],
              command: 'setAreaGroup',
              params: {
                area_group: areaGroupName
              }
            }
          };
          this.deviceService.commandDevice(payloads).subscribe(
            async () => {
              resolve();
            },
            error => {
              Helper.handleError(error, this.translateService, this.dialogService);
              return;
            }
          );
        } else {
          resolve();
        }
      } else {
        resolve();
      }
    });
  }

  /**
   * time out
   * @param ms millisecond
   * @returns
   */
  private async timeout(ms: any): Promise<any> {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
  /**
   * call api realtime status request
   *
   * @param isChangedShutdownTime
   * @param isChangedDisplay
   */
  private async callAPIStatus(isChangedShutdownTime?: boolean, isChangedDisplay?: boolean): Promise<void> {
    return new Promise<void>(resolve => {
      let payloadStatus = { id: [this.deviceEdit.registrationId], timeoutSec: this.TIMEOUT_SEC };
      this.apiCustomerService.getRealtimeStatusRequest(payloadStatus).subscribe(
        data => {
          this.executingService.executed();
          const dataCompleted = _.get(data?.['completed'], `[0]`, undefined);
          if (dataCompleted) {
            if (
              isChangedShutdownTime &&
              dataCompleted.shutdown_time !== this.getShutdownTime(this.deviceEdit.isAutoShutdown, this.shutDownTimeClone, true)
            ) {
              this.handleErrorFromApi(this.ERROR_SHUTDOWN_TIME);
              return;
            }
            if (
              isChangedDisplay &&
              (dataCompleted.display_size != this.convertDisplaySize(this.deviceScreenSize.width, this.deviceScreenSize.height) ||
                dataCompleted.display_direction != this.deviceEdit.screenOrientation.toLowerCase())
            ) {
              this.handleErrorFromApi(this.ERROR_DISPLAY);
              return;
            }
          } else {
            this.handleErrorFromApi(this.ERROR_STATUS);
            return;
          }
          resolve();
        },
        () => {
          this.handleErrorFromApi(this.ERROR_STATUS);
          return;
        }
      );
    });
  }

  /**
   * true if changed display
   *
   * @returns
   */
  private changedDisplay(): boolean {
    return (
      this.deviceEdit.screenOrientation != this.deviceEditOld.screenOrientation ||
      this.convertDisplaySize(this.deviceScreenSize.width, this.deviceScreenSize.height) !=
        this.convertDisplaySize(this.deviceScreenSizeOld.width, this.deviceScreenSizeOld.height)
    );
  }

  /**
   * true if changed display
   *
   * @returns
   */
  private changedAreaGroup(): boolean {
    return this.deviceEdit.customTag2?.name != this.deviceEditOld.customTag2?.name;
  }

  /**
   * true if changed display
   *
   * @returns
   */
  private changedAnnouncementGroup(): boolean {
    return this.deviceEdit.customTag1?.name != this.deviceEditOld.customTag1?.name;
  }

  /**
   * true if changed shutdown time
   *
   * @returns
   */
  private changedShutdownTime(): boolean {
    return (
      this.getShutdownTime(this.deviceEdit.isAutoShutdown, this.deviceEdit.timeAutoShutDown) !=
      this.getShutdownTime(this.deviceEditOld.isAutoShutdown, this.deviceEditOld.timeAutoShutDown)
    );
  }

  /**
   * convert display size
   *
   * @param width
   * @param height
   * @returns
   */
  private convertDisplaySize(width: any, height: any): any {
    return `${width}x${height}`;
  }

  /**
   * get shutdown time
   *
   * @param isAutoShutdown
   * @param isReplace
   * @param time
   * @returns
   */
  private getShutdownTime(isAutoShutdown: any, time: any, isReplace?: any): any {
    const shutdownTime = moment(time).format(this.FORMAT_TIME_AUTO_SHUTDOWN);
    return isAutoShutdown ? (isReplace ? shutdownTime.replace(':', '') : shutdownTime) : null;
  }

  /**
   * validate device
   */
  private validateDevice(): boolean {
    let registrationIDObj = this.deviceForm.value.registrationID;
    let registrationID: string = '';
    let inputError;
    let isValid = true;
    let re = /[^0-9A-F]/;
    for (var key in registrationIDObj) {
      if (registrationIDObj[key] == '' && isValid) {
        isValid = false;
        inputError = key;
      }
      registrationID += registrationIDObj[key];
    }
    if (registrationID.length < DialogDeviceManagerComponent.LENGTH_REGISTRATION_ID) {
      this.dialogService.showDialog(
        DialogMessageComponent,
        {
          data: {
            title: `Error`,
            text: `Registration ID must contain ${DialogDeviceManagerComponent.LENGTH_REGISTRATION_ID} hexadecimal uppercase characters.`
          }
        },
        result => {
          if (!result) {
            document.getElementById(inputError).focus();
          }
        }
      );
      return false;
    }

    let match = re.exec(registrationID);
    if (match) {
      this.dialogService.showDialog(
        DialogMessageComponent,
        {
          data: {
            title: `Error`,
            text: `Registration ID must contain ${DialogDeviceManagerComponent.LENGTH_REGISTRATION_ID} hexadecimal uppercase characters.`
          }
        },
        result => {
          if (!result) {
            const inputElement = document.getElementById(`regisIdNumber${match.index + 1}`) as HTMLInputElement;
            inputElement.focus();
            inputElement.select();
          }
        }
      );
      return false;
    }

    if (!this.validateScreenSize(this.deviceForm.value.screenSizeWidth, this.deviceForm.value.screenSizeHeight)) {
      return false;
    }

    if (
      this.deviceForm.value.deviceId?.trim().length > 0 &&
      this.deviceForm.value.deviceId.length > DialogDeviceManagerComponent.MAX_LENGTH_DEVICE_ID
    ) {
      document.getElementById('deviceID')?.focus();
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: `Error`,
          text: `Device ID must contain no more than ${DialogDeviceManagerComponent.MAX_LENGTH_DEVICE_ID} characters.`
        }
      });
      return false;
    }
    if (this.deviceForm.value.busId?.trim().length > DialogDeviceManagerComponent.MAX_LENGTH_BUS_ID) {
      document.getElementById('busID')?.focus();
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: `Error`,
          text: `Bus ID must contain no more than  ${DialogDeviceManagerComponent.MAX_LENGTH_BUS_ID} characters.`
        }
      });
      return false;
    }
    if (this.deviceForm.value.comment?.trim().length > DialogDeviceManagerComponent.MAX_LENGTH_DEVICE_COMMENT) {
      document.getElementById('description')?.focus();
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: `Error`,
          text: `Comment must contain no more than ${DialogDeviceManagerComponent.MAX_LENGTH_DEVICE_COMMENT} characters.`
        }
      });
      return false;
    }
    return true;
  }

  /**
   * handle event for input registration id
   * @param event
   * @param i
   * @param inputID
   * @returns
   */
  public handleEvent(event: any, i: any, inputID: any): void {
    if (!inputID.value) {
      return;
    }
    let inputRegistrantionIdNext = event.srcElement.nextElementSibling;
    if (!inputRegistrantionIdNext) {
      return;
    }
    if (inputID.value.match(/[\u3000-\u303f\u3040-\u309f\u30a0-\u30ff\uff00-\uff9f\u4e00-\u9faf\u3400-\u4dbf]/)) {
      inputID.value = '';
      return;
    }
    this.characterRegistrationIds[i] = inputID.value;
    inputRegistrantionIdNext.focus();
    inputRegistrantionIdNext.select();
  }

  /**
   * handling ctrl V registration
   * @param event
   * @param index index input mouse focus
   * @param inputID
   * @returns
   */
  public pasteEvent(event: ClipboardEvent, index: number, inputID: any): void {
    let clipboardData = event.clipboardData;
    let registrationID = clipboardData.getData('text');
    for (let i = index; i < this.characterRegistrationIds.length; i++) {
      if (!registrationID[i - index]) {
        break;
      }
      if (registrationID[i - index].match(/[\u3000-\u303f\u3040-\u309f\u30a0-\u30ff\uff00-\uff9f\u4e00-\u9faf\u3400-\u4dbf]/)) {
        inputID.value = '';
        return;
      }
      this.characterRegistrationIds[i] = registrationID[i - index];
    }
    this.validateForm();
    if (registrationID.length + index > DialogDeviceManagerComponent.LENGTH_REGISTRATION_ID) {
      document.getElementById(`regisIdNumber${DialogDeviceManagerComponent.LENGTH_REGISTRATION_ID}`).focus();
      return;
    }
    document.getElementById(`regisIdNumber${registrationID.length + index}`).focus();
  }

  /**
   * update config for picker
   */
  private updateConfig(): void {
    this.config = {
      locale: Helper.getLocale(this.languageKey)
    };
  }

  /**
   * handling Decimal
   *
   * @param inputNumber
   * @param input
   */
  public handlingDecimal(inputNumber: number, input: any): void {
    input.value = Helper.handlingDecimal(inputNumber);
  }

  /**
   * validate width, height of screen size
   *
   * @param width
   * @param height
   * @returns
   */
  private validateScreenSize(width: any, height: any): boolean {
    if (width == null || width < Constant.MIN_SCREEN_SIZE) {
      this.handleError('min-width', Constant.MIN_SCREEN_SIZE);
      return false;
    }
    if (width > Constant.MAX_SCREEN_SIZE) {
      this.handleError('max-width', Constant.MAX_SCREEN_SIZE);
      return false;
    }
    if (height == null || height < Constant.MIN_SCREEN_SIZE) {
      this.handleError('min-height', Constant.MIN_SCREEN_SIZE);
      return false;
    }
    if (height > Constant.MAX_SCREEN_SIZE) {
      this.handleError('max-height', Constant.MAX_SCREEN_SIZE);
      return false;
    }
    return true;
  }

  /**
   * handle error from Api
   *
   * @param error
   */
  private handleErrorFromApi(error: any): any {
    this.dialogService.showDialog(DialogMessageComponent, {
      data: {
        title: this.translateService.instant('dialog-message.error-title'),
        text: this.translateService.instant(`dialog-device-manager.${error}`)
      }
    });
  }

  /**
   * handle error
   *
   * @param error
   * @param value
   */
  private handleError(error: any, value: any): any {
    this.dialogService.showDialog(DialogMessageComponent, {
      data: {
        title: this.translateService.instant('dialog-message.error-title'),
        text: Helper.formatString(this.translateService.instant(`dialog-device-manager.${error}`), `${value}`)
      }
    });
  }
}

/**
 * export dialog data
 */
export interface DialogData {
  device: Device;
}
