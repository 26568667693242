import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DeliveryJobStatusEnum, DeviceStatusEnum } from 'app/config/constants';
import { DeliveryJob } from 'app/model/entity/delivery-job';
import { DeliveryManagerSetting } from 'app/model/entity/delivery-manager-setting';
import { Device } from 'app/model/entity/device';
import { DeliveryJobService } from 'app/service/delivery-job.service';
import { DeviceService } from 'app/service/device.service';
import { DialogService } from 'app/service/dialog.service';
import _ from 'lodash';
import { DateTime } from 'luxon';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DialogConfirmComponent } from '../dialog-confirm/dialog-confirm.component';
import { DialogMessageComponent } from '../dialog-message/dialog-message.component';

@Component({
  selector: 'dialog-delivery-job',
  templateUrl: './dialog-delivery-job.component.html',
  styleUrls: ['./dialog-delivery-job.component.scss']
})
export class DialogDeliveryJobComponent implements OnInit, OnDestroy {
  public DeliveryJobStatusEnum = DeliveryJobStatusEnum;
  public DeviceStatusEnum = DeviceStatusEnum;
  public deliveryJobs: DeliveryJob[];
  public jobSelected: DeliveryJob;
  private deliveryJobsInProgress: DeliveryJob[];
  public isCheckedAllDevices: boolean = false;
  private intervalUpdateProgressForJobs: any;
  private intervalUpdateProgressForDetailedJob: any;
  private autoRefreshDataTime: number = 0;
  private subject$ = new Subject();

  constructor(
    public dialogRef: MatDialogRef<DialogDeliveryJobComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private deliveryJobService: DeliveryJobService,
    private deviceService: DeviceService,
    private dialogService: DialogService
  ) {}

  ngOnInit(): void {
    setTimeout(() => {
      if (this.data.deliveryManagerSetting.statusAutoRefreshTime) {
        this.autoRefreshDataTime = this.data.deliveryManagerSetting.statusAutoRefreshTime * 1000;
      }
      this.fetchDataDeliveryJobs();
    });
  }

  ngOnDestroy(): void {
    // clear all interval
    this.clearIntervalForComponent(this.intervalUpdateProgressForJobs);
    this.clearIntervalForComponent(this.intervalUpdateProgressForDetailedJob);
  }

  /**
   * fetch data delivery jobs
   */
  private fetchDataDeliveryJobs() {
    this.deliveryJobService.getDeliveryJobs().subscribe(
      async jobsData => {
        if (!jobsData || jobsData.length == 0) {
          return;
        }
        this.deliveryJobs = jobsData;
        // sort jobs
        this.sortJobs(this.deliveryJobs);
        // get in progress jobs
        this.deliveryJobsInProgress = _.clone(this.deliveryJobs).filter(
          job =>
            job.status != DeliveryJobStatusEnum.COMPLETED &&
            job.status != DeliveryJobStatusEnum.CANCELLED &&
            job.status != DeliveryJobStatusEnum.FAILED
        );
        this.handleUpdateStatusForJobs();
      },
      error => {
        this.handleErrorFromApi(error);
      }
    );
  }

  /**
   * handle update state for jobs
   */
  private handleUpdateStatusForJobs() {
    if (this.deliveryJobsInProgress.length == 0) {
      return;
    }
    this.updateProgressFirstTimeForJobs().then(() => {
      if (this.deliveryJobsInProgress.length == 0 || !this.data.deliveryManagerSetting.isStatusAutoRefresh) {
        return;
      }
      this.intervalUpdateProgressForJobs = this.updateProgressForJobsInterval();
    });
  }

  /**
   * update progress first time for jobs
   *
   * @param deliveryJobs
   */
  private async updateProgressFirstTimeForJobs() {
    return new Promise<void>((resolve, reject) => {
      this.deliveryJobService.updateProgressFirstTimeForJobs(this.deliveryJobsInProgress).subscribe(
        deliveryJobsData => {
          this.updateCurrentStatusForJobs(deliveryJobsData);
          resolve();
        },
        error => {
          this.handleErrorFromApi(error);
        }
      );
    });
  }

  /**
   * update progress for jobs
   *
   * @param deliveryJobs
   */
  private updateProgressForJobsInterval() {
    return setInterval(() => {
      if (this.deliveryJobsInProgress.length == 0) {
        this.clearIntervalForComponent(this.intervalUpdateProgressForJobs);
        return;
      }
      this.deliveryJobService
        .updateProgressForJobs(this.deliveryJobsInProgress)
        .pipe(takeUntil(this.subject$))
        .subscribe(
          deliveryJobsData => {
            this.updateCurrentStatusForJobs(deliveryJobsData);
            if (this.deliveryJobsInProgress.length == 0) {
              this.clearIntervalForComponent(this.intervalUpdateProgressForJobs);
            }
          },
          error => {
            this.clearIntervalForComponent(this.intervalUpdateProgressForJobs);
            this.handleErrorFromApi(error);
          }
        );
    }, this.autoRefreshDataTime);
  }

  /**
   * update state for jobs
   *
   * @param deliveryJobsData
   */
  private updateCurrentStatusForJobs(deliveryJobsData: DeliveryJob[]) {
    deliveryJobsData.forEach(jobData => {
      let deliveryJob = this.deliveryJobs.find(job => job.id == jobData.id);
      if (deliveryJob) {
        deliveryJob.progress = jobData.progress;
        deliveryJob.status = jobData.status;
        if (deliveryJob.status != DeliveryJobStatusEnum.IN_PROGRESS) {
          this.deliveryJobsInProgress = this.deliveryJobsInProgress.filter(job => job.id != deliveryJob.id);
        }
      }
    });
    // sort jobs
    this.sortJobs(this.deliveryJobs);
  }

  /**
   * update progress first time for detailed job
   */
  private async updateProgressFirstTimeForDetailedJob() {
    return new Promise<void>((resolve, reject) => {
      this.deliveryJobService.updateProgressFirstTimeForDetailedJob(this.jobSelected).subscribe(
        deliveryJobData => {
          this.updateCurrentStatusForDevices(deliveryJobData);
          if (this.jobSelected.status == DeliveryJobStatusEnum.COMPLETED || this.jobSelected.status == DeliveryJobStatusEnum.FAILED) {
            this.deliveryJobsInProgress = this.deliveryJobsInProgress.filter(job => job.id != this.jobSelected.id);
          }
          resolve();
        },
        error => {
          this.handleErrorFromApi(error);
        }
      );
    });
  }

  /**
   * update progress for detailed job
   *
   * @param deliveryJob
   */
  private updateProgressForDetailedJobInterval() {
    return setInterval(() => {
      if (this.jobSelected.status == DeliveryJobStatusEnum.COMPLETED || this.jobSelected.status == DeliveryJobStatusEnum.FAILED) {
        this.clearIntervalForComponent(this.intervalUpdateProgressForDetailedJob);
        return;
      }
      this.deliveryJobService
        .updateProgressForDetailedJob(this.jobSelected)
        .pipe(takeUntil(this.subject$))
        .subscribe(
          deliveryJobData => {
            this.updateCurrentStatusForDevices(deliveryJobData);
            if (this.jobSelected.status == DeliveryJobStatusEnum.COMPLETED || this.jobSelected.status == DeliveryJobStatusEnum.FAILED) {
              this.deliveryJobsInProgress = this.deliveryJobsInProgress.filter(job => job.id != this.jobSelected.id);
              this.clearIntervalForComponent(this.intervalUpdateProgressForDetailedJob);
            }
          },
          error => {
            this.clearIntervalForComponent(this.intervalUpdateProgressForDetailedJob);
            this.handleErrorFromApi(error);
          }
        );
    }, this.autoRefreshDataTime);
  }

  /**
   * update current status for devices
   */
  private updateCurrentStatusForDevices(deliveryJobData: DeliveryJob) {
    this.jobSelected.devices.forEach(device => {
      const deviceData = deliveryJobData.devices.find(dv => dv.id == device.id);
      if (deviceData) {
        device.numberOfStatusUpdates = deviceData.numberOfStatusUpdates;
        device.status = deviceData.status;
        device.progress = deviceData.progress;
        device.updateStatusDate = deviceData.updateStatusDate;
        if (
          device.status != DeviceStatusEnum.COMPLETED &&
          device.status != DeviceStatusEnum.FAILED &&
          device.status != DeviceStatusEnum.CANCELLED
        ) {
          device.elapsedTime = this.getElapsedTime();
        }
      }
    });
    this.jobSelected.devices = this.sortDevices(this.jobSelected.devices);
    this.jobSelected.progress = deliveryJobData.progress;
    this.jobSelected.status = deliveryJobData.status;
  }

  /**
   * show detailed job by double click mouse
   *
   * @param job
   */
  public showDetailedJob(job: DeliveryJob, $event: any) {
    if (!job || $event.target.id == 'checkBoxJob' || $event.target.id == 'deleteJobElement') {
      return;
    }
    if (job.status == DeliveryJobStatusEnum.CANCELLED) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: `Error`,
          text: `Job is cancelled.`
        }
      });
      return;
    }
    this.clearIntervalForComponent(this.intervalUpdateProgressForJobs);
    this.jobSelected = job;
    this.deviceService.getDevicesByJobId(this.jobSelected.jobId).subscribe(
      async devicesData => {
        this.jobSelected.devices = devicesData.map(deviceDataResponse => this.convertDataDeviceFromBackend(deviceDataResponse));
        this.jobSelected.devices = this.sortDevices(this.jobSelected.devices);
        if (
          this.jobSelected.status == DeliveryJobStatusEnum.COMPLETED ||
          this.jobSelected.status == DeliveryJobStatusEnum.CANCELLED ||
          this.jobSelected.status == DeliveryJobStatusEnum.FAILED
        ) {
          return;
        }
        await this.updateProgressFirstTimeForDetailedJob().then(() => {
          if (
            this.jobSelected.status == DeliveryJobStatusEnum.COMPLETED ||
            this.jobSelected.status == DeliveryJobStatusEnum.CANCELLED ||
            this.jobSelected.status == DeliveryJobStatusEnum.FAILED ||
            !this.data.deliveryManagerSetting.isStatusAutoRefresh
          ) {
            return;
          }
          this.intervalUpdateProgressForDetailedJob = this.updateProgressForDetailedJobInterval();
        });
      },
      error => {
        this.handleErrorFromApi(error);
      }
    );
  }

  /**
   * back to job list
   */
  public backToJobList() {
    this.clearIntervalForComponent(this.intervalUpdateProgressForDetailedJob);
    this.jobSelected = undefined;
    this.handleUpdateStatusForJobs();
  }

  /**
   * check job
   *
   * @param index
   */
  public checkJob(job: DeliveryJob) {
    if (!job || (job.status != DeliveryJobStatusEnum.IN_PROGRESS && job.status != DeliveryJobStatusEnum.FAILED)) {
      return;
    }
    job.isChecked = !job.isChecked;
  }

  /**
   * check all devices
   */
  public checkAllDevices() {
    this.isCheckedAllDevices = !this.isCheckedAllDevices;
    this.jobSelected.devices.forEach(device => {
      device.isSelected = this.isCheckedAllDevices;
    });
  }

  /**
   * check device
   *
   * @param index
   */
  public checkDevice(device: Device) {
    event.stopPropagation();
    device.isSelected = !device.isSelected;
    this.isCheckedAllDevices = this.jobSelected.devices.every(device => device.isSelected);
  }

  /**
   * cancel checked job
   */
  public async cancelCheckedJob() {
    let checkedJobs = this.deliveryJobs.filter(job => job.isChecked);
    if (checkedJobs.length == 0) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: 'Error',
          text: 'Please select job.'
        }
      });
      return;
    }
    this.dialogService.showDialog(
      DialogConfirmComponent,
      {
        data: {
          text: checkedJobs.length > 1 ? 'Do you want to cancel checked jobs?' : 'Do you want to cancel checked job?',
          button1: 'Yes',
          button2: 'No',
          title: 'Confirmation'
        }
      },
      async result => {
        if (result) {
          this.clearIntervalForComponent(this.intervalUpdateProgressForJobs);
          let jobIds = checkedJobs.map(job => job.jobId);
          const promises = jobIds.map(jobId => {
            return new Promise<void>((resolve, reject) => {
              this.deliveryJobService.cancelDeliveryJobByCusApi(jobId).subscribe(
                dataResponse => {
                  if (dataResponse['error']) {
                    this.dialogService.showDialog(DialogMessageComponent, {
                      data: {
                        title: `Error`,
                        text: `Job Id ${jobId}: ${dataResponse['error'].type}.`
                      }
                    });
                    checkedJobs = checkedJobs.filter(job => job.jobId != jobId);
                    reject(dataResponse['error'].type);
                    return;
                  }
                  let checkedDeliveryJob = checkedJobs.find(job => job.jobId == jobId);
                  if (checkedDeliveryJob) {
                    checkedDeliveryJob.cancelledDevices = dataResponse['cancelled'];
                    if (dataResponse['cancelled'].length != 0) {
                      checkedDeliveryJob.status = DeliveryJobStatusEnum.CANCELLED;
                    } else if (dataResponse['alreadyUpdated'].length != 0) {
                      checkedDeliveryJob.status = DeliveryJobStatusEnum.COMPLETED;
                    }
                  }
                  this.deliveryJobsInProgress = this.deliveryJobsInProgress.filter(job => job.jobId != jobId);
                  resolve();
                },
                error => {
                  checkedJobs = checkedJobs.filter(job => job.jobId != jobId);
                  this.handleErrorFromApi(error);
                }
              );
            });
          });
          await Promise.all(promises).catch(error => {
            console.log(`Cancel job error: ${error}`);
          });
          if (checkedJobs.length == 0) {
            return;
          }
          this.deliveryJobs.forEach(job => {
            job.isChecked = false;
          });
          this.deliveryJobService.saveCancelledDeliveryJobs(checkedJobs).subscribe(
            async () => {
              this.handleUpdateStatusForJobs();
            },
            error => {
              this.handleErrorFromApi(error);
            }
          );
        }
      }
    );
  }

  /**
   * delete job
   *
   * @param job
   */
  public deleteJob(job: DeliveryJob) {
    if (job.status == DeliveryJobStatusEnum.IN_PROGRESS) {
      return;
    }
    this.dialogService.showDialog(
      DialogConfirmComponent,
      {
        data: {
          text: `Do you want to delete this job?`,
          button1: 'Yes',
          button2: 'No',
          title: 'Confirmation'
        }
      },
      result => {
        if (result) {
          this.clearIntervalForComponent(this.intervalUpdateProgressForJobs);
          this.deliveryJobService.deleteDeliveryJobByCusApi(job.jobId).subscribe(
            dataResponse => {
              if (dataResponse['error']) {
                this.dialogService.showDialog(DialogMessageComponent, {
                  data: {
                    title: `Error`,
                    text: `${dataResponse['error'].type}.`
                  }
                });
                return;
              }
              this.deliveryJobService.deleteDeliveryJob(job.jobId).subscribe(
                () => {
                  this.deliveryJobs = this.deliveryJobs.filter(deliveryJob => deliveryJob.jobId != job.jobId);
                  this.handleUpdateStatusForJobs();
                },
                error => {
                  this.handleErrorFromApi(error);
                }
              );
            },
            error => {
              this.handleErrorFromApi(error);
            }
          );
        }
      }
    );
  }

  /**
   * delete all job
   */
  public deleteAllRemovableJobs() {
    const deletedJobs = this.deliveryJobs.filter(
      job => job.status == DeliveryJobStatusEnum.COMPLETED || job.status == DeliveryJobStatusEnum.CANCELLED
    );
    if (deletedJobs.length == 0) {
      return;
    }
    this.dialogService.showDialog(
      DialogConfirmComponent,
      {
        data: {
          text: `Do you want to delete all removable jobs?`,
          button1: 'Yes',
          button2: 'No',
          title: 'Confirmation'
        }
      },
      async result => {
        if (result) {
          this.clearIntervalForComponent(this.intervalUpdateProgressForJobs);
          let deletedJobIds = deletedJobs.map(job => job.jobId);
          const promises = deletedJobs.map(job => {
            return new Promise<void>((resolve, reject) => {
              this.deliveryJobService.deleteDeliveryJobByCusApi(job.jobId).subscribe(
                dataResponse => {
                  if (dataResponse['error']) {
                    this.dialogService.showDialog(DialogMessageComponent, {
                      data: {
                        title: `Error`,
                        text: `${dataResponse['error'].type}.`
                      }
                    });
                    deletedJobIds = deletedJobIds.filter(jobId => jobId != job.jobId);
                    reject(dataResponse['error'].type);
                    return;
                  }
                  resolve();
                },
                error => {
                  deletedJobIds = deletedJobIds.filter(jobId => jobId != job.jobId);
                  this.handleErrorFromApi(error);
                }
              );
            });
          });
          await Promise.all(promises).catch(error => {
            console.log(`Delete job error: ${error}`);
          });
          if (deletedJobIds.length == 0) {
            return;
          }
          this.deliveryJobService.deleteAllRemovableJobs(deletedJobIds).subscribe(
            () => {
              this.deliveryJobs = this.deliveryJobs.filter(job => !deletedJobIds.includes(job.jobId));
              this.handleUpdateStatusForJobs();
            },
            error => {
              this.handleErrorFromApi(error);
            }
          );
        }
      }
    );
  }

  /**
   * back to delivery manager
   */
  public backToDeliveryManager() {
    const checkedDevices = this.jobSelected.devices.filter(device => device.isSelected);
    if (checkedDevices.length == 0) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: `Error`,
          text: `Please select device.`
        }
      });
      return;
    }
    this.clearIntervalForComponent(this.intervalUpdateProgressForJobs);
    this.clearIntervalForComponent(this.intervalUpdateProgressForDetailedJob);
    this.dialogRef.close(checkedDevices);
  }

  /**
   * close dialog by x button
   */
  public closeDialog() {
    this.clearIntervalForComponent(this.intervalUpdateProgressForJobs);
    this.clearIntervalForComponent(this.intervalUpdateProgressForDetailedJob);
    this.dialogRef.close();
  }

  /**
   * clear interval
   *
   * @param interval
   */
  private clearIntervalForComponent(interval: any) {
    if (interval) {
      clearInterval(interval);
      this.cancelHttpRequest();
    }
  }

  /**
   * sort jobs
   * first priority: completed > in progress > cancelled
   * second priority: start date ASC
   *
   * @param jobs
   */
  private sortJobs(jobs: DeliveryJob[]) {
    const jobOrder = Object.values(DeliveryJobStatusEnum);
    return jobs.sort(
      (job1, job2) =>
        jobOrder.indexOf(job1.status as any) - jobOrder.indexOf(job2.status as any) ||
        DateTime.fromFormat(job1.startDate, 'LLL. dd, yyyy').startOf('day') -
          DateTime.fromFormat(job2.startDate, 'LLL. dd, yyyy').startOf('day')
    );
  }

  /**
   * sort devices
   * first priority: isOverDeadline > isUpcomingDeadline > normal
   * second priority: deadline date ASC
   *
   * @param devices
   */
  private sortDevices(devices: Device[]) {
    let sortedDevices = _.clone(devices);
    return sortedDevices.sort(
      (dv1, dv2) =>
        +dv2.isOverDeadline - +dv1.isOverDeadline ||
        +dv2.isUpcomingDeadline - +dv1.isUpcomingDeadline ||
        DateTime.fromFormat(dv1.deliveryDeadline, 'LLL. dd, yyyy').startOf('day') -
          DateTime.fromFormat(dv2.deliveryDeadline, 'LLL. dd, yyyy').startOf('day')
    );
  }

  /**
   * convert data device
   *
   * @param deviceDataResponse
   */
  private convertDataDeviceFromBackend(deviceDataResponse: any): Device {
    let device = new Device();
    device.id = deviceDataResponse['id'];
    device.registrationId = deviceDataResponse['registrationId'];
    device.busId = deviceDataResponse['busId'];
    device.elapsedTime = deviceDataResponse['elapsedTime'];
    device.status = deviceDataResponse['status'];
    device.deliveryDeadline = deviceDataResponse['deliveryDeadline'];
    device.deviceId = deviceDataResponse['deviceId'];
    device.comment = deviceDataResponse['comment'];
    device.customTag0 = deviceDataResponse['customTag0'];
    device.customTag1 = deviceDataResponse['customTag1'];
    device.customTag2 = deviceDataResponse['customTag2'];
    device.customTag3 = deviceDataResponse['customTag3'];
    device.customTag4 = deviceDataResponse['customTag4'];
    device.dateRegistration = deviceDataResponse['dateRegistration'];
    device.modelName = deviceDataResponse['modelName'];
    device.serialNo = deviceDataResponse['serialNo'];
    device.updateStatusDate = deviceDataResponse['updateStatusDate'];
    return device;
  }

  /**
   * handle error from Api
   *
   * @param error
   */
  private handleErrorFromApi(error: any) {
    console.log(error);
    this.dialogService.showDialog(DialogMessageComponent, {
      data: {
        title: `Error`,
        text: `An error has occurred. Please try again.`
      }
    });
  }

  /**
   * cancel http request
   */
  private cancelHttpRequest() {
    this.subject$.next();
    this.subject$.complete();
  }

  /**
   * convert format elapsed time
   */
  private getElapsedTime(): string {
    const shiftedDuration = DateTime.fromISO(this.jobSelected.startTime)
      .diffNow()
      .negate()
      .shiftTo('days', 'hours', 'minutes');
    return (
      (shiftedDuration.days == 0 ? '' : shiftedDuration.days == 1 ? '1 day ' : `${shiftedDuration.days} days `) +
      ((shiftedDuration.hours == 0 ? '' : shiftedDuration.hours == 1 ? '1 hour ' : `${shiftedDuration.hours} hours `) +
        `${Math.floor(shiftedDuration.minutes)} min`)
    );
  }

  /**
   * check display delete all job button
   * @returns
   */
  public checkDisplayDeleteAllJobButton() {
    return this.deliveryJobs?.some(job => job.status == DeliveryJobStatusEnum.COMPLETED || job.status == DeliveryJobStatusEnum.CANCELLED);
  }
}

export interface DialogData {
  readonly deliveryManagerSetting: DeliveryManagerSetting;
}
