import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Device } from 'app/model/entity/device';
import { PublishSetting } from 'app/model/entity/publish-setting';
import { DevicePublishInfoService } from 'app/service/device-publish-info.service';
import { DialogService } from 'app/service/dialog.service';
import { ToastrService } from 'ngx-toastr';
import { PublishDeviceIdGroup } from '../dialog-delivery-simple/dialog-delivery-simple.component';
import { DialogMessageComponent } from '../dialog-message/dialog-message.component';
@Component({
  selector: 'dialog-delivery-upload',
  templateUrl: './dialog-delivery-upload.component.html',
  styleUrls: ['./dialog-delivery-upload.component.scss']
})
export class DialogDeliveryUploadComponent implements OnInit {
  /**
   * list all device
   */
  listDeviceSelected: Array<Device> = new Array<Device>();

  loading: boolean;

  constructor(
    public dialogRef: MatDialogRef<DialogDeliveryUploadComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogService: DialogService,
    private devicePublishInfoService: DevicePublishInfoService,
    private toast: ToastrService,
    private translateService: TranslateService
  ) {
    this.loading = true;
  }

  ngOnInit() {
    this.createDeliveryJob();
  }

  /**
   * create delivery job
   */
  private createDeliveryJob() {
    this.devicePublishInfoService.delivery(this.data.listDeviceSelected).subscribe(
      dataResponse => {
        let response = JSON.parse(dataResponse.response);
        if (response['error']) {
          this.dialogService.showDialog(
            DialogMessageComponent,
            {
              data: {
                title: this.translateService.instant('dialog-error.title'),
                text: `${response['error'].type}.`
              }
            },
            () => {
              this.dialogRef.close();
            }
          );
        } else {
          this.loading = false;
          this.toast.success('Create Job Successfully.', '');
        }
      },
      error => {
        this.handleError();
      }
    );
  }

  /**
   * handle error
   *
   */
  private handleError() {
    this.dialogService.showDialog(DialogMessageComponent, {
      data: {
        title: this.translateService.instant('dialog-error.title'),
        text: this.translateService.instant('dialog-error.msg')
      }
    });
  }
}

/**
 * export dialog data
 */
export interface DialogData {
  listDeviceSelected: Array<Device>;
  isTimetable: boolean;
  dataSetting: PublishSetting;
  deviceInfos: Array<PublishDeviceIdGroup>;
  timeDateLine: any;
}
