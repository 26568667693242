import { ScheduleRegistrationDetail } from 'app/model/entity/schedule-registration-detail';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Helper } from 'app/common/helper';
import { Constant, ScreenNameEnum } from 'app/config/constants';
import { CalendarInformation } from 'app/model/entity/calendar-information';
import { DialogService } from 'app/service/dialog.service';
import { TimetableContentDayService } from 'app/service/timetable-content-day.service';
import * as fileSaver from 'file-saver';
import moment from 'moment';
import { DialogMessageComponent } from '../dialog-message/dialog-message.component';
import { ScheduleContentDayService } from 'app/service/schedule-content-day.service';
import { CommonService } from 'app/service/common.service';

@Component({
  selector: 'dialog-export-calendar',
  templateUrl: './dialog-export-calendar.component.html',
  styleUrls: ['./dialog-export-calendar.component.scss']
})
export class DialogExportCalendarComponent implements OnInit {
  /**
   * start date
   */
  startDate: Date;
  /**
   * finish date
   */
  finishDate: Date;

  constructor(
    private dialogService: DialogService,
    private translateService: TranslateService,
    private timetableContentDayService: TimetableContentDayService,
    private scheduleContentDayService: ScheduleContentDayService,
    public dialogRef: MatDialogRef<DialogExportCalendarComponent>,
    private commonService: CommonService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  ngOnInit(): void {}

  /**
   * set date
   * @param isStartDate
   * @param event
   */
  public setDate(isStartDate: boolean, event): void {
    if (isStartDate) {
      this.startDate = new Date(event);
    } else {
      this.finishDate = new Date(event);
    }
  }

  /**
   * validate start date, finish date
   * @returns
   */
  private validateDate(): boolean {
    const commonObject = this.commonService.getCommonObject();
    // start date empty
    if (!this.startDate) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('dialog-export-calendar.start-invalid')
        },
        disableClose: true
      });
      return false;
    }
    // finish date empty
    if (!this.finishDate) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('dialog-export-calendar.finish-invalid')
        },
        disableClose: true
      });
      return false;
    }
    // start date invalid
    let date = Helper.getCurrentByTimezoneSetting(commonObject, false);
    const currentDate = Helper.getDateByDay(date.getFullYear(), date.getMonth() + 1, date.getDate());
    if (this.startDate < currentDate) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('dialog-export-calendar.start-invalid')
        },
        disableClose: true
      });
      return false;
    }
    // finish date invalid
    date.setFullYear(date.getFullYear() + Constant.MAX_YEAR);
    date.setDate(date.getDate() - 1);
    let dateEnd = Helper.getDateByDay(date.getFullYear(), date.getMonth() + 1, date.getDate());
    if (this.finishDate > dateEnd || this.finishDate < this.startDate) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('dialog-export-calendar.finish-invalid')
        },
        disableClose: true
      });
      return false;
    }
    return true;
  }

  /**
   * export calendar
   * @returns
   */
  public exportCalendar(): void {
    // validate date
    if (!this.validateDate()) {
      return;
    }
    let exportCalendar: any;
    let calendarInfo: CalendarInformation = new CalendarInformation();
    // export calendar timetable
    if (this.data.screen == ScreenNameEnum.TIME_TABLE) {
      calendarInfo = new CalendarInformation(this.data.deviceId, this.startDate, this.finishDate, this.data.deviceName);
      exportCalendar = this.timetableContentDayService.exportCalendar(calendarInfo);
    } else {
      // export calendar schedule registration
      calendarInfo = new CalendarInformation(null, this.startDate, this.finishDate, null);
      exportCalendar = this.scheduleContentDayService.exportCalendarScheduleRegistration(calendarInfo);
    }
    exportCalendar.subscribe(
      response => {
        if (!response.body.size) {
          this.dialogService.showDialog(DialogMessageComponent, {
            data: {
              title: this.translateService.instant('dialog-error.title'),
              text: this.translateService.instant('dialog-export-calendar.no-data')
            },
            disableClose: true
          });
          return;
        }
        const fileNameResponse = response.headers.get('content-disposition');
        const file = new File([response.body], fileNameResponse, {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        });
        const fileName =
          this.data.screen == ScreenNameEnum.TIME_TABLE
            ? `${calendarInfo.deviceName}_${moment(new Date()).format(Constant.FORMAT_DATE_EXPORT)}.xlsx`
            : `${this.translateService.instant('dialog-export-calendar.file-name-export')}_${moment(new Date()).format(
                Constant.FORMAT_DATE_EXPORT
              )}.xlsx`;
        fileSaver.saveAs(file, fileName);
        this.dialogRef.close();
      },
      error => Helper.handleError(error, this.translateService, this.dialogService)
    );
  }
}

/**
 * interface DialogData
 */
export interface DialogData {
  /**
   * id's device
   */
  deviceId: Number;
  /**
   * name's device
   */
  deviceName: string;
  /**
   * screen
   */
  screen: string;
}
