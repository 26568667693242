import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Helper } from 'app/common/helper';
import { Constant } from 'app/config/constants';
import { Common } from 'app/model/entity/common';
import { CommonTable } from 'app/model/entity/commonTable';
import { CommonTableService } from 'app/service/common-table.service';
import { CommonService } from 'app/service/common.service';
import { DialogService } from 'app/service/dialog.service';
import _ from 'lodash';
import { Subscription } from 'rxjs';
import { DialogMessageComponent } from '../dialog-message/dialog-message.component';
@Component({
  selector: 'dialog-duplicate-setting',
  templateUrl: './dialog-duplicate-setting.component.html',
  styleUrls: ['./dialog-duplicate-setting.component.scss']
})
export class DialogDuplicateSettingComponent implements OnInit {
  duplicateSetting: any = {};
  /**
   * language key
   */
  public languageKey: string;

  /**
   * commonObject
   */
  commonObject: Common;
  /**
   * reference column
   */
  listActive: any[] = new Array<any>();
  public referenceColumnArray: any[];
  subscriptions: Array<Subscription> = new Array<Subscription>();
  constructor(
    private dialogRef: MatDialogRef<DialogDuplicateSettingComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private commonTableService: CommonTableService,
    private translateService: TranslateService,
    private commonService: CommonService,
    private dialogService: DialogService
  ) {
    this.subscriptions.push(
      this.translateService.onLangChange.subscribe((langChangeEvent: LangChangeEvent) => {
        this.languageKey = this.commonService.getCommonObject().setting?.language;
        // multiple language column header
        this.multiLanguageReferenceRowAndColumn();
      })
    );
    this.commonObject = this.commonService.getCommonObject();
  }

  ngOnInit(): void {
    this.languageKey = this.commonObject?.setting?.language;
    this.duplicateSetting = _.cloneDeep(this.data.duplicateSetting);
    this.multiLanguageReferenceRowAndColumn();
  }
  /**
   * on destroy
   */
  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  save(): void {
    if (!this.validateBeforeSave()) {
      return;
    }
    this.duplicateSetting.column4 = Helper.encodeHTML(this.duplicateSetting.column4);
    let commonTableSave = new CommonTable(Constant.KEY_DUPLICATE_SETTING, JSON.stringify(this.duplicateSetting));
    this.commonTableService.save(commonTableSave).subscribe(data => this.dialogRef.close(this.duplicateSetting));
    this.dialogRef.close(this.duplicateSetting);
  }

  close(): void {
    this.dialogRef.close(undefined);
  }

  validateBeforeSave(): boolean {
    if (
      this.duplicateSetting.column1 &&
      this.duplicateSetting.column1 != -1 &&
      this.duplicateSetting.column2 &&
      this.duplicateSetting.column2 != -1 &&
      (!this.duplicateSetting.column3 || this.duplicateSetting.column3 == -1) &&
      (!this.duplicateSetting.column4 || this.duplicateSetting.column4 == -1)
    ) {
      return true;
    } else if (
      this.duplicateSetting.column1 &&
      this.duplicateSetting.column1 != -1 &&
      this.duplicateSetting.column2 &&
      this.duplicateSetting.column2 != -1 &&
      this.duplicateSetting.column3 &&
      this.duplicateSetting.column3 != -1 &&
      this.duplicateSetting.column4 &&
      this.duplicateSetting.column4 != -1
    ) {
      return true;
    } else if (
      (!this.duplicateSetting.column1 || this.duplicateSetting.column1 == -1) &&
      (!this.duplicateSetting.column2 || this.duplicateSetting.column2 == -1) &&
      (!this.duplicateSetting.column3 || this.duplicateSetting.column3 == -1) &&
      (!this.duplicateSetting.column4 || this.duplicateSetting.column4 == -1)
    ) {
      return true;
    } else {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('dialog-duplicate-setting.error')
        }
      });
      return false;
    }
  }

  /**
   * multi Language Reference Row And Column
   */
  private multiLanguageReferenceRowAndColumn(): void {
    this.referenceColumnArray = [];
    for (let i = 1; i < this.data.properties.length - 1; ++i) {
      if (i == 1) {
        this.referenceColumnArray.push(this.translateService.instant('dialog-duplicate-setting.time'));
      }
      this.referenceColumnArray.push(Helper.formatString(this.translateService.instant(`lcd-layout-editor.detail.item`), `${i}`));
    }
    if (this.referenceColumnArray.length - 1 < this.duplicateSetting.column1) {
      this.duplicateSetting.column1 = -1;
    }
    if (this.referenceColumnArray.length - 1 < this.duplicateSetting.column2) {
      this.duplicateSetting.column2 = -1;
    }
    if (this.referenceColumnArray.length - 1 < this.duplicateSetting.column3) {
      this.duplicateSetting.column3 = -1;
    }
    if (this.duplicateSetting.column1 && this.duplicateSetting.column1 != -1) {
      this.listActive.push(this.duplicateSetting.column1);
    }
    if (this.duplicateSetting.column2 && this.duplicateSetting.column2 != -1) {
      this.listActive.push(this.duplicateSetting.column2);
    }
  }

  changeItem() {
    this.listActive = [];
    if (this.duplicateSetting.column1 && this.duplicateSetting.column1 != -1) {
      this.listActive.push(this.duplicateSetting.column1);
    }
    if (this.duplicateSetting.column2 && this.duplicateSetting.column2 != -1) {
      this.listActive.push(this.duplicateSetting.column2);
    }
  }
}

export interface DialogData {
  properties: any[];
  duplicateSetting: any;
}
