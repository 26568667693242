import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { AppDesignDTO } from 'app/model/entity/ticket-editor/dto/app-design-DTO';
import { ApplicationDTO } from 'app/model/entity/ticket-editor/dto/application-DTO';
import { DialogService } from 'app/service/dialog.service';
import { TicketEditorService } from 'app/service/ticket-editor.service';
import { DialogMessageComponent } from '../dialog-message/dialog-message.component';

@Component({
  selector: 'dialog-delivery-application-ticket-editor',
  templateUrl: './dialog-delivery-application-ticket-editor.component.html',
  styleUrls: ['./dialog-delivery-application-ticket-editor.component.scss']
})
export class DialogDeliveryApplicationTicketEditorComponent implements OnInit {
  public appdesign: AppDesignDTO;
  public application: ApplicationDTO;
  public languageKey: string;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private ticketService: TicketEditorService,
    private dialogRef: MatDialogRef<DialogDeliveryApplicationTicketEditorComponent>,
    private dialogService: DialogService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.application = this.data.appSelected;
    this.appdesign = this.data.appDesignSelected;
    this.languageKey = this.data.languageKey;
  }

  delivery(): void {
    if (this.appdesign.appDesignId) {
      this.ticketService.deliveyAppDesign(this.data.informationAccount, this.appdesign?.appDesignId).subscribe(res => {
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: this.translateService.instant('ticket-editor.tilte-delivery-success'),
            text: this.translateService.instant('ticket-editor.delivery-success')
          }
        });
        this.dialogRef.close(res);
      });
    }
  }
}

export interface DialogData {
  appSelected: ApplicationDTO;
  appDesignSelected: AppDesignDTO;
  informationAccount: any;
  languageKey: string;
}
