import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Helper } from 'app/common/helper';
import { DeliveryManagerSetting } from 'app/model/entity/delivery-manager-setting';
import { ValidationFormService } from 'app/service/validation-form.service';

@Component({
  selector: 'dialog-delivery-manager-setting',
  templateUrl: './dialog-delivery-manager-setting.component.html',
  styleUrls: ['./dialog-delivery-manager-setting.component.scss']
})
export class DialogDeliveryManagerSettingComponent implements OnInit {
  settingForm: FormGroup;
  submitted: boolean = false;
  propertyName: { [key: string]: string } = {
    statusAutoRefreshTime: 'Status auto refresh time',
    deliveryDeadline: 'Delivery deadline'
  };
  readonly DEFAULT_STATUS_AUTO_REFRESH = 10;
  readonly MIN_STATUS_AUTO_REFRESH = 3;
  readonly MAX_STATUS_AUTO_REFRESH = 60;
  readonly DEFAULT_DELIVERY_DEADLINE = 10;
  readonly MIN_DELIVERY_DEADLINE = 1;
  readonly MAX_DELIVERY_DEADLINE = 30;

  constructor(
    public dialogRef: MatDialogRef<DialogDeliveryManagerSettingComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private formBuilder: FormBuilder,
    private validationFormService: ValidationFormService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.settingForm = this.formBuilder.group({
      statusAutoRefreshTime: [
        this.data.deliveryManagerSetting.statusAutoRefreshTime,
        [
          this.validationFormService.invalidEmpty,
          Validators.min(this.MIN_STATUS_AUTO_REFRESH),
          Validators.max(this.MAX_STATUS_AUTO_REFRESH)
        ]
      ],
      deliveryDeadline: [
        this.data.deliveryManagerSetting.deliveryDeadline,
        [this.validationFormService.invalidEmpty, Validators.min(this.MIN_DELIVERY_DEADLINE), Validators.max(this.MAX_DELIVERY_DEADLINE)]
      ]
    });
    this.changeDetectorRef.detectChanges();
  }

  public toggleStatusAutoRefresh() {
    this.data.deliveryManagerSetting.isStatusAutoRefresh = !this.data.deliveryManagerSetting.isStatusAutoRefresh;
    if (this.data.deliveryManagerSetting.isStatusAutoRefresh) {
      this.settingForm.get('statusAutoRefreshTime').enable();
      if (!this.data.deliveryManagerSetting.statusAutoRefreshTime) {
        this.data.deliveryManagerSetting.statusAutoRefreshTime = this.DEFAULT_STATUS_AUTO_REFRESH;
      }
    } else {
      this.settingForm.get('statusAutoRefreshTime').disable();
    }
  }

  public save() {
    if (this.data.deliveryManagerSetting.isStatusAutoRefresh) {
      this.settingForm.get('statusAutoRefreshTime').enable();
    } else {
      this.settingForm.get('statusAutoRefreshTime').disable();
    }
    this.submitted = true;
    if (this.settingForm.valid) {
      if (!this.data.deliveryManagerSetting.isStatusAutoRefresh) {
        this.data.deliveryManagerSetting.statusAutoRefreshTime = null;
      }
      this.dialogRef.close(this.data.deliveryManagerSetting);
    }
  }
  /**
   * handling Decimal
   */
  handlingDecimal(inputNumber: number, input: any) {
    input.value = Helper.handlingDecimal(inputNumber);
  }
}

export interface DialogData {
  deliveryManagerSetting: DeliveryManagerSetting;
}
