import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Helper } from 'app/common/helper';
import { Constant } from 'app/config/constants';
import { AppDesignDTO } from 'app/model/entity/ticket-editor/dto/app-design-DTO';
import { ApplicationDTO } from 'app/model/entity/ticket-editor/dto/application-DTO';
import { DialogService } from 'app/service/dialog.service';
import { TicketEditorService } from 'app/service/ticket-editor.service';
import _ from 'lodash';
import { DialogMessageComponent } from '../dialog-message/dialog-message.component';
@Component({
  selector: 'dialog-duplicate-application-design',
  templateUrl: './dialog-duplicate-application-design.component.html',
  styleUrls: ['./dialog-duplicate-application-design.component.scss']
})
export class DialogDuplicateApplicationDesignComponent implements OnInit {
  listApp: Array<ApplicationDTO> = new Array<ApplicationDTO>(); // list Application
  appIdModel: string; // App id by data element
  designNameModel: string; // Design name by data element
  @ViewChild('designName') designNameElementRef: ElementRef;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogDataDuplicateApplicationDesign,
    private dialogRef: MatDialogRef<DialogDuplicateApplicationDesignComponent>,
    private dialogService: DialogService,
    private translateService: TranslateService,
    private ticketEditorService: TicketEditorService
  ) {
    this.designNameModel = data.appDesignSelected.appDesignName;
  }

  ngOnInit(): void {
    this.ticketEditorService.findAllApplication(this.data.informationAccount).subscribe(res => {
      this.listApp = Helper.convertResApplication(res);
      if (this.listApp.length > 0) {
        this.appIdModel = this.listApp[0].appId;
      }
    });
  }

  /**
   * Duplicate
   */
  duplicate(): void {
    if (!this.validateDesignName(this.designNameModel)) {
      return;
    }
    const result = _.cloneDeep(this.data);
    result.appId = this.appIdModel;
    result.designName = this.designNameModel;
    result.appDesignSelected = this.data.appDesignSelected;
    this.dialogRef.close(result);
  }

  /**
   * validate designName when add new/ edit
   * @param designName
   */
  validateDesignName(designName: string): boolean {
    // validate app name
    if (designName.trim().length < Constant.MIN_NO_LENGTH || designName.trim().length > Constant.MAXIMUM_TEXT_LENGTH) {
      this.designNameElementRef.nativeElement.focus();
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('ticket-editor.msg.validate-app-design-name')
        }
      });
      return false;
    }

    return true;
  }

  /**
   * Select Application
   */
  selectApplication(appIdModel: string): void {
    this.appIdModel = appIdModel;
  }

  /**
   * changeDisplay
   * @param value
   * @returns
   */
  changeDisplay(value): String {
    let temp = _.cloneDeep(value)?.toString();
    if (temp?.split('W')?.length > 7 && temp?.length > 15) {
      value = value?.substring(0, 15) + '...';
    } else if (value?.length > 20) {
      value = value?.substring(0, 20) + '...';
    }
    return value;
  }
}

export interface DialogDataDuplicateApplicationDesign {
  informationAccount: any;
  appDesignSelected: AppDesignDTO;
}
