import { Helper } from 'app/common/helper';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Constant, ReferencePositionTimetableColumnEnum } from 'app/config/constants';
import { CommonTable } from 'app/model/entity/commonTable';
import { CommonTableService } from 'app/service/common-table.service';
import { DialogService } from 'app/service/dialog.service';
import _ from 'lodash';
import { DialogMessageComponent } from '../dialog-message/dialog-message.component';

@Component({
  selector: 'dialog-edit-header',
  templateUrl: './dialog-edit-header.component.html',
  styleUrls: ['./dialog-edit-header.component.scss']
})
export class DialogEditHeaderComponent implements OnInit {
  /**
   * list item header
   */
  headerItems: any[];
  /**
   * list item header
   */
  headerItemsOrigin: any[];

  referencePositionColumns: number[];

  listLastItemEdit: any[];

  commonTable: CommonTable;

  hasTimeColumn: boolean;
  /**
   * true if changed schedule header
   */
  isChangeScheduleHeader: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<DialogEditHeaderComponent>,
    private dialogService: DialogService,
    private commonTableService: CommonTableService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    if (this.data) {
      this.headerItems = this.data[0].headers;
      this.referencePositionColumns = this.data[1];
      this.hasTimeColumn = this.referencePositionColumns.some(reference => reference == ReferencePositionTimetableColumnEnum.TIME);
      this.isChangeScheduleHeader = this.data[0].isChangeScheduleHeader;
    }
    this.listLastItemEdit = new Array(Constant.MAX_NUMBER_ITEM_HEADER).fill(null);
    this.commonTable = new CommonTable(Constant.KEY_LAST_TIMETABLE_ITEM_EDIT);
    if (this.isChangeScheduleHeader) {
      this.headerItemsOrigin = _.cloneDeep(this.headerItems);
    } else {
      this.commonTableService.getValueCommonTableByKey(Constant.KEY_LAST_TIMETABLE_ITEM_EDIT).subscribe(response => {
        if (response) {
          this.commonTable = response;
          this.listLastItemEdit = JSON.parse(response['value']);
          for (const reference of this.referencePositionColumns) {
            if (this.listLastItemEdit[reference]) {
              this.headerItems[reference] = this.listLastItemEdit[reference];
            }
          }
        }
        this.headerItemsOrigin = _.cloneDeep(this.headerItems);
      });
    }
  }

  /**
   * change value item
   * @param event
   * @param i
   */
  public changeItemHeader(event, i) {
    this.headerItems[i] = event;
  }

  /**
   * save item header
   */
  save() {
    if (this.headerItems.some((item, index) => index != 0 && item?.trim() === '')) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('dialog-edit-header.item-empty')
        }
      });
      return;
    } else if (this.headerItems.some((item, index) => index != 0 && item && item.length > 16)) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('dialog-edit-header.item-length')
        }
      });
      return;
    }
    this.referencePositionColumns.forEach(reference => {
      this.listLastItemEdit[reference] = this.headerItems[reference];
    });
    this.commonTable.value = JSON.stringify(this.listLastItemEdit);
    this.commonTableService.save(this.commonTable).subscribe(
      result => {
        this.dialogRef.close(this.headerItems);
      },
      error => Helper.handleError(error, this.translateService, this.dialogService)
    );
  }
}
