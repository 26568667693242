import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Helper } from 'app/common/helper';
import { Constant } from 'app/config/constants';
import { Common } from 'app/model/entity/common';
import { Ticket } from 'app/model/entity/ticket';
import { ApplicationDTO } from 'app/model/entity/ticket-editor/dto/application-DTO';
import { CommonService } from 'app/service/common.service';
import { TicketEditorService } from 'app/service/ticket-editor.service';
import _ from 'lodash';
import moment from 'moment';
import { DatePickerDirective } from 'ng2-date-picker';
import { Subscription } from 'rxjs';

@Component({
  selector: 'dialog-export-reservation',
  templateUrl: './dialog-export-reservation.component.html',
  styleUrls: ['./dialog-export-reservation.component.scss']
})
export class DialogExportReservationComponent implements OnInit {
  listApp: Array<ApplicationDTO> = new Array<ApplicationDTO>();
  listOfReservedTickets: Array<Ticket> = new Array<Ticket>();
  appIdSelected: string;
  ticketIdSelected: number;
  subscriptions: Array<Subscription> = new Array<Subscription>(); //array subscription
  public languageKey: string;
  commonObject: Common; // common object
  targetYearAndMonth: any;
  timeFromDisplay: string;
  config: any;
  private readonly FORMAT_DATE = 'YYYY-MM-DD';
  constructor(
    public translateService: TranslateService,
    private commonService: CommonService,
    private datePipe: DatePipe,
    private ticketEditorService: TicketEditorService,
    public dialogRef: MatDialogRef<DialogExportReservationComponent>,
    private cdr: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.subscriptions.push(
      this.translateService.onLangChange.subscribe((langChangeEvent: LangChangeEvent) => {
        this.languageKey = this.commonService.getCommonObject().setting?.language;
      })
    );
    this.commonObject = this.commonService.getCommonObject();
  }

  async ngOnInit(): Promise<void> {
    await this.getAllApplication();
    this.appIdSelected = _.cloneDeep(this.data.appIdSelected);
    await this.getListTicketsWithReservations(this.appIdSelected);
    this.ticketIdSelected = _.cloneDeep(this.data.ticketIdSelected);
    this.cdr.detectChanges();
    this.languageKey = this.commonObject?.setting?.language;
    this.updateConfig();
    this.targetYearAndMonth = this.data.targetYearAndMonth ? this.data.targetYearAndMonth : null;
    this.timeFromDisplay = this.targetYearAndMonth ? this.formatToYearMonth(this.targetYearAndMonth) : null;
  }

  /**
   * open date picker
   * @param time selected
   * @param isFrom true if open date picker time from
   */
  openDatePicker(picker: DatePickerDirective, time: any) {
    picker.api.open();
    this.addPseudoSpan();
    picker.api.moveCalendarTo(time ?? moment());
  }

  /**
   * add element date picker
   */
  addPseudoSpan(): void {
    while (document.getElementById('span-new')) {
      document.getElementById('span-new').remove();
    }
    return;
  }

  /**
   * update config for picker
   *
   * @param period
   */
  private updateConfig(): void {
    this.config = {
      showWeekNumbers: false,
      format: this.FORMAT_DATE,
      firstDayOfWeek: 'mo',
      unSelectOnClick: false,
      locale: Helper.getLocale(this.languageKey)
    };
  }

  /**
   * onDateChange
   * @param e
   */
  async onDateChange(e?): Promise<void> {
    this.timeFromDisplay = this.formatToYearMonth(this.targetYearAndMonth);
  }

  formatToYearMonth(date: string): string {
    const dateObj = new Date(date);
    return this.datePipe.transform(dateObj, 'yyyy/MM');
  }

  /**
   * get list application
   * @param appSelect
   */
  async getAllApplication(): Promise<void> {
    return new Promise<void>(resolve => {
      this.ticketEditorService.findAllApplication(this.data?.informationAccount).subscribe(res => {
        this.listApp = Helper.convertResApplication(res);
        resolve();
      });
    });
  }

  /**
   * getListTicketsWithReservations
   * @param appId
   * @returns
   */
  async getListTicketsWithReservations(appId: string): Promise<void> {
    return new Promise<void>(resolve => {
      this.ticketEditorService.getTickets(this.data.informationAccount, appId, null, true).subscribe(res => {
        this.listOfReservedTickets = res;
        this.ticketIdSelected = this.listOfReservedTickets[Constant.FIRST_ELEMENT_INDEX]?.ticketId;
        resolve();
      });
    });
  }

  /**
   * export
   */
  export() {
    if (this.appIdSelected && this.ticketIdSelected && this.targetYearAndMonth) {
      const indexTicket = this.listOfReservedTickets.findIndex(e => e.ticketId == this.ticketIdSelected);
      if (indexTicket == -1) {
        return;
      }
      const isMulti = this.listOfReservedTickets[indexTicket]?.sectionType == Constant.MULTI;
      const result = {
        appId: this.appIdSelected,
        ticketId: this.ticketIdSelected,
        targetYearAndMonth: this.targetYearAndMonth,
        isMultiRegional: isMulti
      };
      this.dialogRef.close(result);
    }
  }

  /**
   * getTitleSelected
   * @param appId
   * @returns
   */
  getTitleAppSelected(appId: string): string {
    let indexApp = this.listApp.findIndex(e => e.appId == appId);
    if (indexApp == -1) {
      return '';
    } else {
      return this.listApp[indexApp].appName;
    }
  }

  /**
   * getTitleSelected
   * @param appId
   * @returns
   */
  getTitleTicketSelected(ticketId: number): string {
    let indexTicket = this.listOfReservedTickets.findIndex(e => e.ticketId == ticketId);
    if (indexTicket == -1) {
      return '';
    } else {
      return this.languageKey == 'en'
        ? this.listOfReservedTickets[indexTicket].ticketName?.en
        : this.listOfReservedTickets[indexTicket].ticketName?.ja;
    }
  }

  /**
   * Get name app display
   * @param value nameApp
   * @returns
   */
  changeDisplay(value: String): String {
    let temp = _.cloneDeep(value)?.toString();
    if (temp?.split('W')?.length > 7 && temp?.length > 20) {
      value = value?.substring(0, 19) + '...';
    } else if (value?.length > 35) {
      value = value?.substring(0, 33) + '...';
    }
    return value;
  }
}
export interface DialogData {
  informationAccount: any;
  appIdSelected: string;
  ticketIdSelected: Ticket;
  targetYearAndMonth: any;
}
