import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'dialog-emergency-mode',
  templateUrl: './dialog-emergency-mode.component.html',
  styleUrls: ['./dialog-emergency-mode.component.scss']
})
export class DialogEmergencyModeComponent implements OnInit {
  constructor(private dialogRef: MatDialogRef<DialogEmergencyModeComponent>) {}

  ngOnInit(): void {}

  /**
   * action click on
   */
  public onMode() {
    this.dialogRef.close(true);
  }

  /**
   * action click off
   */
  public offMode() {
    this.dialogRef.close(false);
  }
}
