import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Helper } from 'app/common/helper';
import { Constant, DeviceStatusEnum } from 'app/config/constants';
import { DeviceCalendar } from 'app/model/entity/device-calendar';
import { PublishSetting } from 'app/model/entity/publish-setting';
import { CommonService } from 'app/service/common.service';
import { DialogService } from 'app/service/dialog.service';
import _ from 'lodash';
import { interval, Subject } from 'rxjs';
import { concatMap, takeUntil } from 'rxjs/operators';
import { DialogConfirmComponent } from '../dialog-confirm/dialog-confirm.component';
import { DialogMessageComponent } from '../dialog-message/dialog-message.component';
import { DeviceService } from 'app/service/device.service';
import { RouteDestination } from 'app/model/entity/destination/route-destination';
import { Device } from 'app/model/entity/device';
import { DeliveryStatusDestinationService } from 'app/service/delivery-status-destination.service';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { PublishDestinationService } from 'app/service/publish-destination.service';
import { EDSDevicePublishInfoService } from 'app/service/eds-device-publish-info.service';
import { PayloadDelivery } from 'app/model/entity/publish-info-timetable';
import { ToastrService } from 'ngx-toastr';
import { DeliveryStatusDestination } from 'app/model/entity/delivery-status-destination';
import { ExecutingService } from 'app/service/executing.service';
import { Common } from 'app/model/entity/common';
@Component({
  selector: 'dialog-delivery-destination',
  templateUrl: './dialog-delivery-destination.component.html',
  styleUrls: ['./dialog-delivery-destination.component.scss']
})
export class DialogDeliveryDestinationComponent implements OnInit {
  /**
   * device calendars
   */
  deviceCalendars: Array<DeviceCalendar>;
  /**
   * route destination
   */
  edsRoutes: Array<RouteDestination>;
  /**
   * true if all device checked
   */
  isCheckedAll: boolean;
  /**
   * DeviceStatusEnum
   */
  DeviceStatusEnum = DeviceStatusEnum;
  /**
   * interval update status for devices
   */
  intervalUpdateStatusForDevices: any;
  /**
   * constant
   */
  readonly TIME_AUTO_REFRESH_DEFAULT = 10;
  /**
   * subject
   */
  private subject$ = new Subject();
  /**
   * original device ids delivered
   */
  originalDeviceIdsDelivered: Array<Number>;

  /**
   * common object
   */
  private commonObject: Common;

  loading: boolean;
  constructor(
    public dialogRef: MatDialogRef<DialogDeliveryDestinationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private dialogService: DialogService,
    private deliveryStatusService: DeliveryStatusDestinationService,
    private translateService: TranslateService,
    private commonService: CommonService,
    private deviceService: DeviceService,
    private publishDestinationService: PublishDestinationService,
    private edsDevicePublishInfoService: EDSDevicePublishInfoService,
    private toast: ToastrService,
    private executingService: ExecutingService
  ) {
    this.loading = true;
    this.commonObject = this.commonService.getCommonObject();
  }

  ngOnInit(): void {
    this.edsRoutes = this.data?.edsRoutes;
    this.deviceService.getDevice().subscribe(
      (data: Array<Device>) => {
        if (!data?.length) {
          return;
        }
        this.deviceCalendars = Helper.convertDevicesToDeviceCalendars(data, this.commonObject);
        this.getStatusForDevices();
      },
      error => {
        this.dialogRef.close();
        this.handleError();
      }
    );
  }

  /**
   * ngOnDestroy
   */
  ngOnDestroy(): void {
    this.clearIntervalForComponent(this.intervalUpdateStatusForDevices);
  }

  /**
   * get status for devices
   */
  public getStatusForDevices(): void {
    this.deliveryStatusService.getDeliveryStatusForDevices().subscribe(
      data => {
        this.deviceCalendars?.forEach(deviceCalendar => {
          const index = data.findIndex(item => item.deviceId == deviceCalendar.id);
          if (index == -1) {
            deviceCalendar.status = '';
            return;
          }
          deviceCalendar.status = data[index].status;
        });
        this.handleUpdateStatusForDevices();
      },
      error => {
        this.dialogRef.close();
        this.handleError();
      }
    );
  }

  /**
   * handle update status for devices
   */
  private async handleUpdateStatusForDevices(): Promise<void> {
    await this.updateStatusFirstTimeForDevices().then(() => {
      this.intervalUpdateStatusForDevices = this.handleUpdateStatusForDevicesInterval();
    });
  }

  /**
   * update status first time for devices
   */
  private async updateStatusFirstTimeForDevices(): Promise<void> {
    const deviceIds = Helper.getDeviceIdsNotYetCompletedDelivery(this.deviceCalendars);
    if (!deviceIds?.length) {
      return;
    }
    return new Promise<void>((resolve, reject) => {
      this.deliveryStatusService.updateStatusFirstTimeForDevices(deviceIds).subscribe(
        deliveryStatusTimes => {
          this.changeStatusForDevices(deliveryStatusTimes);
          resolve();
        },
        error => {
          reject();
          this.handleError();
        }
      );
    });
  }

  /**
   * Handle update status for devices interval
   */
  private handleUpdateStatusForDevicesInterval(): any {
    return interval(this.TIME_AUTO_REFRESH_DEFAULT * 1000)
      .pipe(
        concatMap(num => {
          return this.updateStatusForDevices();
        })
      )
      .subscribe();
  }

  /**
   * Update status for devices
   *
   * @returns
   */
  private updateStatusForDevices(): Promise<void> {
    const deviceIds = Helper.getDeviceIdsNotYetCompletedDelivery(this.deviceCalendars);
    if (!deviceIds?.length) {
      this.clearIntervalForComponent(this.intervalUpdateStatusForDevices);
      return;
    }
    return new Promise<void>((resolve, reject) => {
      this.deliveryStatusService
        .updateStatusForDevices(deviceIds)
        .pipe(takeUntil(this.subject$))
        .subscribe(
          deliveryStatusTimes => {
            this.changeStatusForDevices(deliveryStatusTimes);
            resolve();
          },
          error => {
            reject();
            this.clearIntervalForComponent(this.intervalUpdateStatusForDevices);
            this.handleError();
          }
        );
    });
  }
  /**
   * clear interval
   * @param interval id's interval is running in component
   *
   */
  private clearIntervalForComponent(interval: any): void {
    if (interval) {
      interval.unsubscribe();
      this.cancelHttpRequest();
    }
  }

  /**
   * cancel http request
   */
  private cancelHttpRequest(): void {
    this.subject$.next();
  }

  /**
   * check or uncheck a device
   * @param index index of check-changed device
   * @param e
   */
  public changeChecked(index: number, e): void {
    e.stopPropagation();
    this.deviceCalendars[index].isChecked = !this.deviceCalendars[index].isChecked;
    this.isCheckedAll = this.deviceCalendars?.every(device => device.isChecked);
  }

  /**
   * check or uncheck all device
   */
  public checkAll(): void {
    this.isCheckedAll = !this.isCheckedAll;
    this.deviceCalendars?.forEach(deviceData => (deviceData.isChecked = this.isCheckedAll));
  }

  /**
   * close dialog
   */
  public closeDialog(): void {
    this.clearIntervalForComponent(this.intervalUpdateStatusForDevices);
    this.dialogRef.close();
  }

  /**
   * delivery
   */
  public delivery(): void {
    let checkedDevices = this.deviceCalendars?.filter(device => device.isChecked);
    // no device selected
    if (!checkedDevices?.length) {
      this.handleErrorMessage('no-device-selected');
      return;
    }
    if (checkedDevices.some(device => device.status === DeviceStatusEnum.WAITING || device.status === DeviceStatusEnum.IN_PROGRESS)) {
      this.handleErrorMessage('delivery-process');
      return;
    }
    this.deliveryUpload(checkedDevices);
  }

  /**
   * delivery upload
   *
   * @param checkedDevices
   */
  private deliveryUpload(checkedDevices: Array<DeviceCalendar>): void {
    let dataSetting = new PublishSetting(this.commonService.getCommonObject().userName);
    dataSetting.devicesId = checkedDevices.map(deviceData => deviceData.id);
    dataSetting.timezone = Helper.getUserTimeZone(this.commonService.getCommonObject().setting);

    let dataPublish = Helper.convertDataPublish(this.data.edsRoutes);
    dataSetting.edsRoutes = JSON.stringify(dataPublish.edsRoutes);

    this.clearIntervalForComponent(this.intervalUpdateStatusForDevices);
    this.publishDataDestination(dataSetting, dataPublish.templateIds, checkedDevices);
  }

  /**
   * publish data for destination
   * @param dataSetting
   * @param templateIds
   * @param checkedDevices
   */
  private async publishDataDestination(dataSetting: PublishSetting, templateIds: any, checkedDevices: Array<DeviceCalendar>) {
    this.executingService.executing();
    this.publishDestinationService.publishDataDestination(dataSetting, JSON.stringify(templateIds)).subscribe(
      (event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.Sent:
            console.log('Request has been made!');
            break;
          case HttpEventType.ResponseHeader:
            console.log('Response header has been received!');
            break;
          case HttpEventType.UploadProgress:
            break;
          case HttpEventType.Response:
            console.log('Request succeeded!');
            setTimeout(() => {
              this.edsDevicePublishInfoService.delivery(new PayloadDelivery(dataSetting.devicesId, event.body)).subscribe(
                dataResponse => {
                  this.executingService.executed();
                  let errors = dataResponse?.filter(data => data);
                  if (errors?.length) {
                    this.dialogService.showDialog(
                      DialogMessageComponent,
                      {
                        data: {
                          title: this.translateService.instant('dialog-error.title'),
                          texts: errors
                        }
                      },
                      () => {
                        this.getStatusForDevices();
                      }
                    );
                  } else {
                    this.loading = false;
                    this.toast.success(this.translateService.instant('dialog-delivery-upload.delivery-success'), '');
                    this.getStatusForDevices();
                  }
                },
                () => {
                  this.executingService.executed();
                  this.handleStatusDevicesWhenDeliveryFailed(checkedDevices);
                }
              );
            }, 1000);
        }
      },
      () => {
        this.executingService.executed();
        this.handleStatusDevicesWhenDeliveryFailed(checkedDevices);
      }
    );
  }

  /**
   * Handle status devices when delivery failed
   *
   * @param checkedDevices
   */
  private handleStatusDevicesWhenDeliveryFailed(checkedDevices: Array<DeviceCalendar>) {
    this.deliveryStatusService.updateFailedStatusForDevices(checkedDevices).subscribe(
      deliveryStatusList => {
        if (!deliveryStatusList) {
          this.handleError();
          return;
        }
        deliveryStatusList.forEach(deliveryStatus => {
          let index = this.deviceCalendars.findIndex(device => device.registrationId == deliveryStatus.registrationId);
          if (index != -1) {
            this.deviceCalendars[index].status = deliveryStatus.status;
          }
        });
        this.handleErrorMessage('delivery-failed');
        this.handleUpdateStatusForDevices();
      },
      error => {
        if (error.status == Constant.NETWORK_ERROR_CODE) {
          Helper.showMessageErrorNetWork(this.dialogService, this.translateService);
          return;
        }
        this.handleError();
      }
    );
  }

  /**
   * Handle error message
   *
   * @param message
   */
  private handleErrorMessage(message: string) {
    this.dialogService.showDialog(DialogMessageComponent, {
      data: {
        title: this.translateService.instant('dialog-error.title'),
        text: this.translateService.instant(`dialog-delivery-destination.${message}`)
      }
    });
  }

  /**
   * cancel checked job
   */
  public cancelDelivery(): void {
    let checkedDevices: Array<DeviceCalendar> = this.deviceCalendars?.filter(device => device.isChecked);
    if (!checkedDevices?.length) {
      this.handleErrorMessage('choose-device');
      return;
    }
    this.dialogService.showDialog(
      DialogConfirmComponent,
      {
        data: {
          text:
            checkedDevices.length > 1
              ? this.translateService.instant('dialog-delivery-destination.cancel-devices')
              : this.translateService.instant('dialog-delivery-destination.cancel-device'),
          button1: this.translateService.instant('dialog-delivery-destination.yes'),
          button2: this.translateService.instant('dialog-delivery-destination.no')
        }
      },
      result => {
        if (result) {
          this.clearIntervalForComponent(this.intervalUpdateStatusForDevices);
          // cancel status of device: waiting or in progress
          const devices = checkedDevices
            ?.filter(dv => dv.status == DeviceStatusEnum.WAITING || dv.status == DeviceStatusEnum.IN_PROGRESS)
            ?.map(device => {
              return {
                id: device.id,
                deviceId: device.name
              };
            });

          if (!devices?.length) {
            this.handleCheckedAfterCancelDelivery();
            this.intervalUpdateStatusForDevices = this.handleUpdateStatusForDevicesInterval();
            return;
          }
          this.deliveryStatusService.cancelDelivery(devices).subscribe(
            dataResponse => {
              let errors = dataResponse?.filter(data => data);
              if (errors?.length) {
                this.dialogService.showDialog(
                  DialogMessageComponent,
                  {
                    data: {
                      title: this.translateService.instant('dialog-error.title'),
                      texts: errors
                    }
                  },
                  result => {
                    this.handleCheckedAfterCancelDelivery();
                    this.getStatusForDevices();
                  }
                );
              } else {
                this.handleCheckedAfterCancelDelivery();
                this.getStatusForDevices();
              }
            },
            error => {
              this.handleError();
            }
          );
        }
      }
    );
  }

  /**
   * handle checked after cancel delivery
   */
  private handleCheckedAfterCancelDelivery(): void {
    this.isCheckedAll = false;
    this.deviceCalendars?.forEach(device => (device.isChecked = false));
  }

  /**
   * handle error
   *
   */
  private handleError(): void {
    this.dialogService.showDialog(DialogMessageComponent, {
      data: {
        title: this.translateService.instant('dialog-error.title'),
        text: this.translateService.instant('dialog-error.msg')
      }
    });
  }

  /**
   * change status for devices
   * @param deliveryStatusTimes
   */
  private changeStatusForDevices(deliveryStatusTimes: DeliveryStatusDestination[]): any {
    deliveryStatusTimes.forEach(deliveryStatusTime => {
      const index = this.deviceCalendars.findIndex(item => item.id == deliveryStatusTime.deviceId);
      if (index == -1) {
        return;
      }
      this.deviceCalendars[index].status = deliveryStatusTime.status;
    });
  }
}

/**
 * export dialog data
 */
export interface DialogData {
  deviceCalendars: Array<DeviceCalendar>;
  edsRoutes: Array<RouteDestination>;
}

/**
 * class DeviceInformation
 */
export class DeviceInformation {
  deviceId: Number;
  timeDateLine: number;

  constructor(deviceId?: Number, timeDateLine?: number) {
    this.deviceId = deviceId;
    this.timeDateLine = timeDateLine;
  }
}
